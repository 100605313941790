import { ArrowRight, Code2 } from "lucide-react";
import React from "react";

export default function Hero() {
  return (
    <section
      id="home"
      className="pt-20 pb-32 bg-gradient-to-br from-indigo-50 to-white"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-10 md:mb-0">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 leading-tight mb-6">
              We Build Amazing
              <span className="text-indigo-600"> Digital Solutions</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Transform your business with cutting-edge software solutions. We
              create innovative applications that drive growth and efficiency.
            </p>
            <div className="flex gap-4">
              <a
                href="#contact"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Get Started
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
              <a
                href="#projects"
                className="inline-flex items-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                View Projects
              </a>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full filter blur-3xl opacity-20"></div>
              <Code2 className="relative h-64 w-64 text-indigo-600" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
