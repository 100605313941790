// Navbar.tsx

import { Menu, X, Code2 } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOfferOpen, setIsOfferOpen] = useState(false); // For mobile dropdown

  return (
    <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Code2 className="h-8 w-8 text-indigo-600" />
            <span className="ml-2 text-xl font-bold text-gray-900">
              Remotechies
            </span>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <a
                href="/#home"
                className="text-gray-900 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Home
              </a>
              <a
                href="/#services"
                className="text-gray-900 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Services
              </a>
              <a
                href="/#projects"
                className="text-gray-900 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Projects
              </a>

              {/* Offer Dropdown */}
              <div className="relative group">
                <button className="text-gray-900 hover:text-indigo-600 px-3 py-2 rounded-md text-sm font-medium focus:outline-none">
                  Offer
                </button>
                {/* Dropdown Menu */}
                <div className="absolute top-full left-0 mt-0 w-48 bg-white rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 hidden group-hover:block">
                  <Link
                    to="/website-builder"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Website Builder
                  </Link>
                  {/* Add more dropdown items here if needed */}
                </div>
              </div>

              <a
                href="/#contact"
                className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700"
              >
                Contact Us
              </a>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-900"
              aria-expanded={isOpen}
              aria-label="Toggle navigation menu"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white">
            <a
              href="/#home"
              className="text-gray-900 hover:text-indigo-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </a>
            <a
              href="/#services"
              className="text-gray-900 hover:text-indigo-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              Services
            </a>
            <a
              href="/#projects"
              className="text-gray-900 hover:text-indigo-600 block px-3 py-2 rounded-md text-base font-medium"
            >
              Projects
            </a>

            {/* Mobile Offer Dropdown */}
            <div className="block">
              <button
                onClick={() => setIsOfferOpen(!isOfferOpen)}
                className="w-full text-left text-gray-900 hover:text-indigo-600 px-3 py-2 rounded-md text-base font-medium focus:outline-none"
              >
                Offer
              </button>
              {isOfferOpen && (
                <div className="ml-4 mt-1 space-y-1">
                  <Link
                    to="/website-builder"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsOfferOpen(false)}
                  >
                    Website Builder
                  </Link>
                  {/* Add more dropdown items here if needed */}
                </div>
              )}
            </div>

            <a
              href="/#contact"
              className="bg-indigo-600 text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-indigo-700"
            >
              Contact Us
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}
