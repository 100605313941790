// WebsiteBuilder.tsx

import React from "react";

const WebsiteBuilder: React.FC = () => {
  // Sample data for the websites
  const websites = [
    {
      id: 1,
      name: "School Website",
      description:
        "A comprehensive website for educational institutions to manage courses, events, and resources.",
      imageUrl: "https://via.placeholder.com/400x200.png?text=School+Website",
      link: "#",
    },
    {
      id: 2,
      name: "Hospital Application",
      description:
        "A user-friendly application for hospitals to manage patient records, appointments, and services.",
      imageUrl:
        "https://via.placeholder.com/400x200.png?text=Hospital+Application",
      link: "#",
    },
    {
      id: 3,
      name: "IT Service Website Builder",
      description:
        "A versatile website builder tailored for IT service providers to showcase their offerings and projects.",
      imageUrl:
        "https://via.placeholder.com/400x200.png?text=IT+Service+Website+Builder",
      link: "#",
    },
  ];

  return (
    <section className="website-builder py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-4 text-center">
          Website Builder Samples
        </h2>
        <p className="text-lg text-gray-700 mb-12 text-center">
          Explore our collection of sample websites to get inspired for your
          project.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {websites.map((site) => (
            <div
              key={site.id}
              className="sample bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
            >
              <img
                src={site.imageUrl}
                alt={site.name}
                className="w-full h-40 object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-semibold text-indigo-600 mb-2">
                {site.name}
              </h3>

              {/* Price Section */}
              <div className="bg-indigo-100 text-indigo-600 text-sm font-bold px-3 py-1 rounded-full inline-block mb-4">
                USD 100 Only
              </div>

              <p className="text-gray-600 mb-4">{site.description}</p>
              <a
                href={site.link}
                className="text-indigo-600 hover:text-indigo-800 underline"
              >
                View Sample
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WebsiteBuilder;
